import React, { useEffect } from 'react'
import BlogCard from './BlogCard'
import { Link } from 'react-router-dom'

function RelatedBlogs(blogs) {
  return (
    <>
    { blogs !== null && <div className="w-full mx-auto flex items-center justify-center">
      <div className="w-full mx-auto max-w-[1400px] flex flex-col px-8 mb-20 md:mt-20 mt-10">
        <h2 className="font-extrabold text-white text-3xl uppercase mb-4">
          related <span className="text-primary">blogs</span>
        </h2>
        {/* Image Slider */}
        <div
          className="w-full overflow-x-scroll scrollbar-custom pt-12 flex flex-row justify-start md:gap-14 gap-8  pb-10"
          id="image-track"
        >
          {/* Images to map */}
          {blogs?.blogs.map((blog, index) => (
            <div className='flex items-center justify-center min-w-[300px] max-w-[300px]'  key={index}>
                    <Link to={`/blogs/${blog.id}`}>
                      <BlogCard key={index} blog={blog.attributes} />
                    </Link>            </div>
          ))}
        </div>
      </div>
    </div> }

    </>
 )
}

export default RelatedBlogs