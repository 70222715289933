import { useState, createContext, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import RegionModal from "../components/RegionModal";

const RegionCheckerContext = createContext(null);

export const RegionCheckerProvider = ({ children }) => {
  const location = useLocation();

  const [region, setRegion] = useState(localStorage.getItem("region"));
  const [country, setCountry] = useState(localStorage.getItem("country"));
  const [currency, setCurrency] = useState("$");
  const [openModal, setOpenModal] = useState(false);

  // List of paths where we don't want to show the region modal
  const excludedPaths = [
    '/resetPassword',
    '/forgetPassword',
    '/login',
    '/verifyEmail',
    '/brands',
    '/blogs',
  ];

  const isExcludedPath = (pathname) => {
    // Check static paths
    if (excludedPaths.includes(pathname)) return true;
    // Check dynamic paths
    if (pathname.startsWith('/blogs/')) return true;
    return false;
  };

  useEffect(() => {
    // Show modal only if region or country is not set AND we're not on an excluded path
    if ((!region || !country) && !isExcludedPath(location.pathname)) {
      setOpenModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (region === "europe") setCurrency("€");
    else setCurrency("$");
  }, [region]);

  return (
    <RegionCheckerContext.Provider
      value={{
        region,
        setRegion,
        country,
        setCountry,
        currency,
        openModal,
        setOpenModal,
      }}
    >
      {openModal ? <RegionModal setOpen={setOpenModal} /> : children}
    </RegionCheckerContext.Provider>
  );
};

export const useRegionChecker = () => {
  return useContext(RegionCheckerContext);
};
