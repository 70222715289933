import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import useFetch from "../hooks/useFetch";
import Loading from "../components/Loading";
import BlogCard from "../components/BlogCard";

function BlogListPage() {
  const [page, setPage] = useState(1);

  const { data: blogs, loading: loading } = useFetch(`api/blogs/?sort[0]=publishedAt:desc&populate[thumbnail]=*&populate[products]=*&pagination[pageSize]=10&pagination[page]=${page}`);
  
  return (
    <>
      <Helmet>
        {/* TODO: Adjust title */}
        <title>SkyShop | Blogs</title>
      </Helmet>
      {!loading && blogs ? (
        <div className="w-full mx-auto flex justify-center items-center">
          <div className="max-w-[1400px] w-full">
            <div className="flex flex-col justify-center items-start p-4 md:p-6 lg:p-8 2xl:pl-14 space-y-8">
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                className="!text-white !text-sm !breadcrumbs !scrollbar-thumb-rounded-full !scrollbar-thumb-base-100 !pb-4 !scrollbar-thumb-sm"
              >
                <Link to="/">Home</Link>
                <Link to={`/blogs`}>Blogs</Link>
              </Breadcrumbs>
              <h2 className="text-xl xl:text-3xl font-bold text-white uppercase tracking-wide">
                Blogs
              </h2>
              <div className="h-[2px] w-full bg-primary"></div>
              <div className="w-full justify-center items-center p-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-14 sm:gap-8 lg:gap-12">
                  {blogs.map((blog, index)=>(
                    <Link to={`/blogs/${blog.id}`} key={index}>
                      <BlogCard key={index} blog={blog.attributes} />
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default BlogListPage