import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import useFetch from "../hooks/useFetch";
import Loading from "../components/Loading";

function BlogPage() {
  const { blogId } = useParams();

  const { data: blog, loading } = useFetch(
    `api/blogs/${blogId}?populate[thumbnail]=*&populate[products]=*&populate[blog_tag]=*`
  );

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    };
    return date.toLocaleDateString('en-US', options);
  };

  

  return (
    <>
      <Helmet>
        <title>{blog?.attributes.title}</title>
        <meta name="description" content={blog?.attributes.small_description} />
      </Helmet>
      {!loading && blog ? (
        <div className="w-full mx-auto flex justify-center items-center">
          <div className="max-w-[1400px] w-full">
            <div className="flex flex-col justify-center items-start p-4 md:p-6 lg:p-8 2xl:pl-14 space-y-8">
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                className="!text-white !text-sm !breadcrumbs !scrollbar-thumb-rounded-full !scrollbar-thumb-base-100 !pb-4 !scrollbar-thumb-sm"
              >
                <Link to="/">Home</Link>
                <Link to={`/blogs`}>Blogs</Link>
                <Link to={`/blogs`}>{blog.attributes.title}</Link>
              </Breadcrumbs>
              <div>
                <h2 className="text-xl xl:text-3xl font-bold text-white uppercase tracking-wide mb-4">
                  {blog.attributes.title}
                </h2>
                <p className="text-primary mb-2">
                  {blog.attributes.read_time} min read
                </p>
                <p className="font-medium">
                  Wriiten by {blog.attributes.author} |{" "}
                  {formatDate(blog.attributes.publishedAt)}
                </p>
              </div>

              <div className="h-[2px] w-full bg-primary"></div>
              <div className="flex flex-row flex-wrap gap-4">
                {blog.attributes.products.data.map((product, index) => (
                  <Link to={`/product/${product.attributes.title}`} key={index}>
                    <div
                      key={index}
                      className=" badge badge-primary badge-outline p-4 text-sm max-w-40 cursor-pointer hover:bg-cyan-600 hover:text-white duration-150 ease-in font-medium"
                    >
                      <p className="truncate">{product.attributes.title}</p>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="text-white mt-4x w-full">
                <ReactMarkdown
                  className="prose text-white lg:text-lg tracking-wide min-w-full markdown"
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {blog.attributes.blog}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default BlogPage;
