import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Select, Option } from "@material-tailwind/react";
import { removeAll } from "../redux/cartReducer";
import useFetch from "../hooks/useFetch";
import { useRegionChecker } from "../hooks/regionChecker";
import Loading from "../components/Loading";

function RegionModal({ setOpen }) {
  const dispatch = useDispatch();

  const { setRegion, setCountry } = useRegionChecker();

  const { data: countries, loading } = useFetch(
    `api/shippings/?pagination[page]=1&pagination[pageSize]=300`
  );

  const [regionTmp, setRegionTmp] = useState("");
  const [countryTmp, setCountryTmp] = useState("");

  const handleChange = () => {
    if (regionTmp !== "" && countryTmp !== "") {
      localStorage.setItem("region", regionTmp.toLowerCase());
      localStorage.setItem("country", countryTmp.toUpperCase());
      setRegion(regionTmp.toLowerCase());
      setCountry(countryTmp.toUpperCase());
      dispatch(removeAll());
      setOpen(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      {!loading && countries ? (
        <div className="w-screen mx-auto h-screen flex items-center justify-center p-8 bg-black fixed top-0 left-0 z-[1000000]">
          <div className="h-[300px] rounded-xl border-2 border-primary max-w-xl p-8 flex flex-col justify-between">
            <h1 className="text-secondary-content text-2xl uppercase font-bold mb-4">
              Select Your Country
            </h1>
            <div className="w-full h-[1px] bg-gray-400 mb-4"></div>
            <Select
              color="cyan"
              label="Countries"
              animate={{
                mount: { y: 0 },
                unmount: { y: 25 },
              }}
              className="!ml-0"
              onChange={(e) => {
                setRegionTmp(e.region);
                setCountryTmp(e.code);
              }}
            >
              {countries
                .sort((a, b) =>
                  a.attributes.country_name.localeCompare(
                    b.attributes.country_name
                  )
                )
                ?.map((country, index) => (
                  <Option key={index} value={country.attributes}>
                    {country.attributes.country_name}
                  </Option>
                ))}
            </Select>
            <button className="btn btn-primary mt-4" onClick={handleChange}>
              Select
            </button>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default RegionModal;
