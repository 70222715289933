import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { Card, Input, Typography } from "@material-tailwind/react";
import { Helmet } from "react-helmet";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reset = queryParams.get("reset");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (reset) handleToast();
  }, [reset]);

  const handleToast = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  };

  const CloseToast = () => {
    setShowToast(false);
  };

  // handling login flow
  const handleLogin = async () => {
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    if (email !== "" && password !== "") {
      setError(false);
      setErrorMessage("");
      try {
        const res = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "api/auth/local",
          {
            identifier: email,
            password,
          }
        );
        
        if (res) {
          setError(false);
          setErrorMessage("");
          localStorage.setItem("jwt", res.data.jwt);
          localStorage.setItem("username", res.data.user.username);

          if (location.state?.from) {
            navigate(location.state.from, { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        }
      } catch (err) {
        setError(true);
        if(err.response.data.error.message === "Invalid identifier or password") {
          setErrorMessage("Incorrect Password");
        } else {
          setErrorMessage(err.response.data.error);
        }
      }
    }
    setError(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>SkyShop - Login</title>
      </Helmet>
      <div className="w-full h-full min-h-screen min-w-screen relative">
        <div className="absolute w-full h-full left-0 top-0 overflow-hidden z-0">
          {/* <video
            src={videoUrl?.attributes.video.data.attributes.url}
            playsInline
            autoPlay
            muted
            type="video/mp4"
            alt=""
            className="h-full w-full object-cover object-center brightness-[0.4] blur-sm"
          ></video> */}
        </div>
        <div className=" absolute left-0 top-0 w-full h-full flex items-center justify-center z-[50] pt-[64px]">
          <div className="w-full md:min-w-[600px] lg:min-w-[600px] h-full flex flex-col my-auto bg-transparent items-center justify-center p-4 md:p-14">
            <Card
              color="transparent"
              shadow={false}
              className="border border-primary p-4 md:p-8 rounded-lg"
            >
              <Typography variant="h3" color="white" className="font-bold">
                Log In
              </Typography>
              <Typography color="white" className="mt-1 font-normal">
                Enter your details to login.
              </Typography>
              <div
                role="form"
                className="mt-8 mb-2 w-70 max-w-screen-lg sm:w-96"
              >
                <div className="mb-4 flex flex-col gap-6">
                  <Input
                    id="email"
                    size="lg"
                    label="Email"
                    color="cyan"
                    className="text-secondary-content"
                    error={error}
                    onKeyDown={handleKeyPress}
                  />
                  <Input
                    id="password"
                    type="password"
                    size="lg"
                    color="cyan"
                    className="text-secondary-content focus:outline-none"
                    label="Password"
                    error={error}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                {error && (
                  <p className="text-secondary-content font-light mb-2">
                    {errorMessage}
                  </p>
                )}
                <button
                  className="btn btn-primary w-full mt-4"
                  onClick={handleLogin}
                >
                  Login
                </button>
                {error && (
                  <div className="pt-2 mb-6">
                    <Link
                      to="/forgetPassword"
                      className="font-medium text-blue-gray-500 link transition-colors hover:text-blue-gray-700"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                )}
                <Typography className="mt-4 text-center font-normal text-secondary-content">
                  Don't have an account?{" "}
                  <Link
                    to="/register"
                    className="font-medium link transition-colors hover:text-blue-gray-400"
                  >
                    Sign Up
                  </Link>
                </Typography>
              </div>
            </Card>
          </div>
        </div>
        <div
          id="toast-notification"
          className={
            showToast
              ? "w-full max-w-[300px] p-4 text-gray-900 bg-white rounded-lg shadow dark:bg-gray-800 dark:text-gray-300 fixed bottom-4 right-4 z-[10000]"
              : "hidden"
          }
          role="alert"
        >
          <div className="flex items-center mb-3">
            <span className="mb-1 text-sm font-semibold text-gray-900 dark:text-white">
              Password Changed
            </span>
            <button
              type="button"
              className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              data-dismiss-target="#toast-notification"
              aria-label="Close"
              onClick={CloseToast}
            >
              <span className="sr-only">Close</span>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
