import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Card, Input, Typography } from "@material-tailwind/react";
import Loading from "../components/Loading";

function ResetPassword() {
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    const [error, setError] = useState(false);
    const [passwordLength, setPasswordLength] = useState(false);

    const handleChangePassword = async () => {
        const password = document.getElementById("newPassword").value;
        const repeatPassword = document.getElementById("repeatNewPassword").value;

        if (password !== "" && repeatPassword !== "" && password === repeatPassword) {
            if (password.length < 6) {
                setPasswordLength(true)
            } else {
                setError(false);
                setPasswordLength(false)
                try {
                    const res = await axios.post(
                        process.env.REACT_APP_BACKEND_URL + "api/auth/reset-password",
                        {
                            code: code,
                            password: password,
                            passwordConfirmation: repeatPassword,
                        }
                    );

                    navigate("/login?reset=true");
                } catch (err) {
                    setError(true);
                    throw new Error(err);
                }
            }

        } else {
            setError(true);
        }
    };

    return (
        <>
            {code ? (
                <div className="w-full h-full min-w-screen min-h-screen relative">
                    <div className=" absolute left-0 top-0 w-full h-full flex items-center justify-center z-[50] pt-[64px]">
                        <div className="w-full md:min-w-[600px] lg:min-w-[600px] h-full flex flex-col my-auto bg-transparent items-center justify-center p-4 md:p-14">
                            <Card
                                color="transparent"
                                shadow={false}
                                className="border border-primary p-4 md:p-8 rounded-lg"
                            >
                                <Typography variant="h3" color="white" className="font-bold">
                                    Reset Password
                                </Typography>
                                <Typography color="white" className="mt-1 font-normal">
                                    Please type in your new password
                                </Typography>
                                <div role="form" className="mt-8 mb-2 w-full max-w-screen-lg">
                                    <div className="mb-4 flex flex-col gap-6">
                                        <Input
                                            id="newPassword"
                                            size="lg"
                                            type="password"
                                            label="Password"
                                            color="cyan"
                                            className="text-secondary-content w-full"
                                            error={error}
                                        />
                                        <Input
                                            id="repeatNewPassword"
                                            size="lg"
                                            type="password"
                                            label="Confirm Password"
                                            color="cyan"
                                            className="text-secondary-content w-full"
                                            error={error}
                                        />
                                    </div>
                                    {error && (
                                        <div className="text-secondary-content">
                                            Passwords Don't Match
                                        </div>
                                    )}
                                    {passwordLength && (
                                        <div className="text-secondary-content">
                                            Password must be at least 6 characters
                                        </div>
                                    )}
                                    <button
                                        className="btn btn-primary w-full mt-4"
                                        onClick={handleChangePassword}
                                        disabled={error || passwordLength }
                                    >
                                        Change Password
                                    </button>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </>
    );
}

export default ResetPassword;
